.modal-success {
  background-color: fade-out(black, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background-color: white;
    position: relative;
    width: 700px;
    padding: 40px 0;

    /** Mobile. */
    @media (max-width: 768px) {
      width: 90vw;
    }

    &-description {
      text-align: center;
      padding: 0 30px;

      /** Mobile. */
      @media (max-width: 767px) {
        width: 100%;
        padding: 0 25px;
      }

      p {
        color: #808080;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.7;
        font-family: sans-serif;

        /** Mobile. */
        @media (max-width: 768px) {
          br {
            display: none;
          }
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      padding: 0 30px;

      /** Mobile. */
      @media (max-width: 768px) {
        flex-direction: column;
      }

      button {
        background-color: #0a302a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 60px;
        margin: 20px 0;
        box-shadow: 0px 15px 22px 0px rgba(19, 27, 46, 0.44);
        transition: letter-spacing 350ms ease, box-shadow 350ms ease;

        a {
          color: #fff;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          bottom: 0;
          font-family: sans-serif;
          font-size: 13px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          transition: letter-spacing 350ms ease, color 350ms ease;
          line-height: 1.4;
        }

        &:hover {
          letter-spacing: 1.5px;
          box-shadow: 0px 8px 12px 0px rgba(19, 27, 46, 0.59);

          a {
            letter-spacing: 1.5px;
          }
        }

        &:not(:last-child) {
          margin-right: 15px;

          /** Mobile. */
          @media (max-width: 768px) {
            margin: 0 0 35px;
          }
        }
      }
    }
  }
}
