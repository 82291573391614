#main-footer {
  width: 100%;
  height: 400px;
  background-color: #fffdf1;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 800px;
    overflow: hidden;
    padding-bottom: 80px;
  }

  .container {
    height: 100%;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 30px 0;

    @media (max-width: 768px) {
      padding: 0 10px;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .top {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .title {
        font-size: 20px;
        color: #fe6421;
        font-weight: 500;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .address {
        font-style: normal;
        margin-top: 10px;
        font-size: 20px;
        color: #0a302a;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 1.4;
        }

        img {
          width: 18px;
          position: relative;
          left: -8px;
          bottom: -3px;
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 30px;

      &:before {
        content: "";
        position: absolute;
        width: 80%;
        height: 1px;
        background-color: #fe6421;
        top: -32px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 768px) {
          top: -60px;
        }
      }

      .img-wrapper {
        width: 55%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
