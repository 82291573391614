.modal-whatsapp {
  background-color: fade-out(black, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  .close {
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    top: -45px;
    right: -45px;

    @media (max-width: 768px) {
      right: 0;
    }

    svg path {
      fill: white;
    }
  }

  .wrapper {
    position: relative;
    width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, .85);

    @media screen and (max-width: 1366px) {
      transform: scale(0.9);
    }

    &-title {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 100px;
      padding: 0 10px;
      width: 100%;
      margin-bottom: -1px;

      @media (max-width: 768px) {
        height: 80px;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.4;
        color: #0a302a;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &-form {
      position: relative;
      width: 100%;
      padding: 35px;

      @media (max-width: 768px) {
        padding: 25px 15px;
      }

      .field-group {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #0a302a;
        transition: border-color time(fast) ease(fade);

        &:not(:last-child) {
          margin-bottom: 50px;
          margin-top: 20px;
        }

        label {
          position: relative;
          bottom: 30px;
          color: #0a302a;
          font-weight: 600;
        }

        .placeholder {
          color: rgba(0, 0, 0, 0.65);
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          bottom: 8px;
          left: 0;
          pointer-events: none;
          transition: opacity time(default) ease(fade);
        }

        .field {
          background-color: transparent;
          color: #000;
          font-size: 18px;
          font-weight: 500;
          margin: 0;
          padding: 0;
          position: relative;
          bottom: 8px;
          border: none;
          width: 100%;
          display: block;
        }

        &.invalid {
          border-color: red;
        }
        &.focus,
        &.not-empty {
          .placeholder {
            opacity: 0;
          }
        }
      }

      .form-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: -10px 0 0;
        padding: 0;

        button {
          background-color: #0a302a;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 600;
          position: relative;
          font-size: 18px;
          text-transform: uppercase;
          width: 100%;
          height: 60px;
          letter-spacing: 1px;
          box-shadow: 0px 6px 35px 2px rgba(211, 186, 124, 0.6);
          transition: letter-spacing 350ms ease, box-shadow 350ms ease,
            background-position 750ms ease;

          &:hover {
            letter-spacing: 1.4px;
            box-shadow: 0px 5px 20px 1px rgba(211, 186, 124, 0.45);
            background-position: right center;
          }
        }
      }

      .terms-option {
        margin-top: 30px;

        .checkbox-whatsapp {
          background-color: transparent;
          padding-left: 25px;
          border: none;
          position: relative;
          margin: 0;
          bottom: -2px;
        }

        .checkbox-text {
          color: black;
          font-size: 11px;
          text-align: left;
          font-weight: normal;

          a {
            font-weight: bold;
            color: black;
          }
        }

        &.active button::before {
          background-color: black;
          border-color: black;
        }
      }
    }
  }
}
