.modal-cta {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .close {
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    top: 15px;
    right: 15px;

    svg path {
      fill: black;
    }
  }

  .wrapper {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .wrapper-title {
      color: black;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.4;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .form {
      width: 100%;
      margin: 0;
      padding: 25px 25px 30px;

      .field-group {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid black;
        transition: border-color time(fast) ease(fade);

        &:not(:last-child) {
          margin-bottom: 60px;
          margin-top: 40px;
        }

        label {
          position: relative;
          bottom: 30px;
          color: #000;
        }

        .placeholder {
          color: rgba(0, 0, 0, 0.4);
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          bottom: 8px;
          left: 0;
          pointer-events: none;
          transition: opacity time(default) ease(fade);
        }

        .field {
          background-color: transparent;
          color: black;
          font-size: 18px;
          font-weight: 500;
          margin: 0;
          padding: 0;
          position: relative;
          bottom: 8px;
          border: none;
          width: 100%;
          display: block;
        }

        &.invalid {
          border-color: red;
        }
        &.focus,
        &.not-empty {
          .placeholder {
            opacity: 0;
          }
        }
      }

      .form-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 30px 0 0;
        padding: 0;

        button {
          background-color: #0a302a;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          position: relative;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          width: 100%;
          height: 60px;
          letter-spacing: 1px;
          box-shadow: 0px 15px 22px 0px rgba(19, 27, 46, 0.44);
          transition: letter-spacing 350ms ease, box-shadow 350ms ease,
            background-position 750ms ease;

          &:hover {
            box-shadow: 0px 8px 12px 0px rgba(19, 27, 46, 0.59);
            background-position: right center;
          }
        }
      }

      .terms-option {
        margin-top: 30px;

        .checkbox {
          background-color: transparent;
          padding-left: 25px;
          border: none;
          position: relative;
          margin: 0;
          bottom: -2px;

          &-text {
            color: black;
            font-size: 12px;
            text-align: left;
            line-height: 1.2;
            font-weight: normal;

            a {
              font-weight: bold;
              color: black;
            }
          }
        }

        &.active button::before {
          background-color: black;
          border-color: black;
        }
      }
    }
  }
}
