#page-home {
  .mobile-ctas {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 45;
    display: flex;
    justify-content: center;
    align-items: center;

    /** Desktop. */
    @media (min-width: 1200px) {
      display: none;
    }

    .cta {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      button {
        background-color: #0a302a;
        border-top: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        height: 60px;
        padding-top: 4px;
        letter-spacing: 2px;
        box-shadow: 2px 8px 12px 2px rgba(19, 27, 46, 1);
        transition:
          letter-spacing 350ms ease,
          box-shadow 350ms ease,
          background-position 750ms ease;

        &:hover {
          box-shadow: 0px 8px 12px 0px rgba(19, 27, 46, 0.59);
          background-position: right center;
        }
      }
    }
  }

  .section-hero {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    aspect-ratio: 16/9;
    min-height: 768px;

    @media (max-width: 768px) {
      aspect-ratio: unset;
      height: calc(100vh - 70px);
      overflow: unset;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 148px;
      height: 130.5px;
      background-image: url(../../src/assets/images/folha-hero.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 6;

      @media (max-width: 768px) {
        width: calc(148px / 2);
        height: calc(130.5px / 2);
      }
    }

    &.full {
      height: 100vh;
      overflow: auto;
    }

    .topo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background-color: #ff4d00;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;

      span {
        color: white;
        text-transform: uppercase;
        letter-spacing: 6px;
        font-size: 10px;

        @media (max-width: 768px) {
          letter-spacing: 4px;
        }
      }
    }

    .rodape {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      background-color: #fffdf1;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;

      @media (max-width: 768px) {
        bottom: -70px;
      }

      span {
        color: #124a41;
        font-size: 26px;
        font-family: "Jitter", sans-serif;

        strong {
          color: #fe6421;
          font-weight: 400;
        }
      }
    }

    .whatsapp {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 45;

      a {
        background-color: #25d366;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1px 1px 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        transition:
          box-shadow 350ms ease,
          background-position 750ms ease;
        box-shadow: 0 0 13px 6px rgba(86, 170, 0, 0.4);

        svg {
          transform-origin: 50% 50%;
          transform: scale(0.8);
        }
      }

      &:hover {
        a {
          &:hover {
            box-shadow: 0 0 16px 12px rgba(86, 170, 0, 0.45);
            background-position: right center;
          }
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      max-height: unset;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      height: 100%;
    }

    .flex-wrapper {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @media (max-width: 1023px) {
        transform: scale(0.8);
        transform-origin: 50% 0;
      }

      @media (max-width: 768px) {
        align-items: center;
        padding-top: 0;
        transform: none;
        position: relative;
        flex-direction: column;
        overflow: hidden;
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 170px;

        @media (max-width: 768px) {
          max-width: 140px;
        }
      }
    }

    .left {
      height: auto;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      transform: scale(0.9);
      padding-left: 140px;
      padding-top: 20px;

      @media (max-width: 1400px) {
        transform: scale(0.75);
      }

      @media (max-width: 1300px) {
        transform: scale(0.8);
      }

      @media (max-width: 768px) {
        padding: 0;
        transform: scale(0.9);
        height: unset;
        width: 100%;
        position: relative;
        top: 30px;
      }

      @media (max-width: 380px) {
        top: 0;
        transform: scale(0.75);
      }

      .logo {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        // justify-content: center;

        @media (max-width: 1400px) {
          margin-bottom: 10px;
        }

        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
        }

        img {
          max-width: 220px;

          &:not(:last-child) {
            margin-bottom: 40px;
          }

          @media (max-width: 768px) {
            max-width: 180px;
          }
        }
      }

      .titulo {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: 768px) {
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .pre-title {
          text-align: center;
          line-height: 1.2;
          font-size: 42px;
          font-weight: 500;
          color: #124a41;
          font-family: "Jitter", sans-serif;

          @media (max-width: 768px) {
            font-size: 22px;
            line-height: 1.8;
            text-align: center;
          }
        }

        .title {
          text-transform: uppercase;
          text-align: center;
          line-height: 1;
          font-size: 42px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #fe6421;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 120px;
            height: 1px;
            background-color: #fe6421;
          }

          @media (max-width: 768px) {
            font-size: 32px;
            text-align: center;
          }
        }
      }

      .tipologia-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;

        @media (max-width: 1400px) {
          margin-top: 0;
        }

        @media (max-width: 768px) {
          text-align: center;
          justify-content: center;
          margin-top: 20px;
        }

        img {
          width: 100%;
          max-width: 250px;

          @media (max-width: 768px) {
            max-width: 200px;
          }
        }
      }

      .selo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        left: 50px;
        margin-top: 10px;

        img {
          max-width: 500px;

          @media (max-width: 768px) {
            max-width: 400px;
          }
        }
      }
    }

    .right-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      top: -20px;

      @media (max-width: 768px) {
        margin-top: 20px;
      }

      .right {
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.9);

        @media screen and (max-width: 1600px) {
          transform: scale(0.9);
        }

        @media (max-width: 768px) {
          margin-top: 60px;
          display: none;
        }

        &-title {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          height: 70px;
          padding: 0 10px;
          width: 100%;

          h3 {
            font-size: 18px;
            color: #0a302a;
            font-family: "Montserrat";
            line-height: 1.2;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
          }
        }

        &-form {
          position: relative;
          width: 100%;
          padding: 20px 35px;

          @media (max-width: 1281px) {
            padding-top: 0;
          }

          .field-group {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #9f9e99;
            transition: border-color time(fast) ease(fade);
            margin: 20px 0 40px 0;

            &:not(:last-child) {
              margin-bottom: 40px;

              @media (max-width: 1281px) {
                margin-bottom: 30px;
              }
            }

            label {
              font-size: 15px;
              color: #fe6421;
              font-weight: 500;
              position: relative;
              bottom: 14px;
            }

            .placeholder {
              color: rgba(0, 0, 0, 0.55);
              font-size: 16px;
              font-weight: 400;
              position: absolute;
              bottom: 8px;
              left: 0;
              pointer-events: none;
              transition: opacity time(default) ease(fade);
            }

            .field {
              background-color: transparent;
              color: #0a302a;
              font-size: 16px;
              font-weight: 500;
              margin: 0;
              padding: 0 0 8px;
              border: none;
              width: 100%;
              display: block;
            }

            &.invalid {
              border-color: red;
            }
            &.focus,
            &.not-empty {
              .placeholder {
                opacity: 0;
              }
            }
          }

          .form-submit {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 30px;
            margin-top: -10px;
            padding: 0;

            button {
              position: relative;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 60px;
              text-align: center;
              cursor: pointer;
              background-color: #fe6421;
              border: 2px solid #fe6421;
              box-shadow: 0px 15px 23.52px 0.48px rgba(0, 0, 0, 0.56);
              font-size: 18px;
              color: #fff;
              transition:
                letter-spacing 350ms ease,
                background-color 350ms ease,
                border 350ms ease,
                color 350ms ease;

              &:hover {
                letter-spacing: 2px;
                background-color: white;
                color: #fe6421;
              }
            }
          }

          .terms-option {
            margin-top: 0;

            .checkbox {
              background-color: transparent;
              padding-left: 25px;
              border: none;
              position: relative;
              margin: 0;
              bottom: -2px;

              &-text {
                color: rgba(0, 0, 0, 0.7);
                font-size: 9px;
                text-align: left;
                line-height: 1.2;
                font-weight: normal;

                a {
                  font-weight: bold;
                  color: #000;
                }
              }
            }

            &.active button::before {
              background-color: #000;
              border-color: #000;
            }
          }
        }
      }
    }
  }

  .section-about {
    position: relative;
    width: 100%;
    background-color: #e9e6d3;
    padding-top: 20px;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 70px 15px 20px 15px;
      aspect-ratio: unset;
      max-height: unset;
      height: auto;
      margin-top: 0;
      background-color: #f8efe9;
      min-height: unset;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 235px;
      height: 178px;
      background-image: url(../../src/assets/images/madeira.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 6;

      @media (max-width: 768px) {
        width: calc(235px / 2);
        height: calc(178px / 2);
        bottom: 0;
        top: unset;
        transform: unset;
      }
    }

    .container {
      height: 100%;

      @media (max-width: 768px) {
        height: unset;
        padding: 0;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .title-wrapper {
      margin: 50px 0;
      position: relative;
    }

    .video {
      position: relative;
      aspect-ratio: 16/9;
      max-width: 900px;
      margin: 0 auto;
      padding: 0;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.45);

      /** Mobile. */
      @media (max-width: 768px) {
        width: 90%;
        height: 230px;
        bottom: 0;
        margin: 0 auto;
        top: 0;
      }

      &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          background-color: black;
          z-index: 1;
          opacity: 0.3;
        }

        img {
          position: relative;
          width: 100%;
          height: 100%;
          filter: blur(1px);
          object-fit: cover;
        }
      }

      &-icon {
        background-image: linear-gradient(to bottom, #fff, #fffdf1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 0 0 0 8px;
        z-index: 2;
        transform-origin: 0% 0%;
        transition: transform time(fast) ease(fade);

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 60px;
          height: 60px;
        }

        svg {
          transform-origin: 50% 50%;
          transition: transform time(fast) ease(fade);

          /** Mobile. */
          @media (max-width: 1023px) {
            transform: scale(0.7);
          }
        }
      }

      &:hover .video-icon {
        transform: scale(0.7) translate(-50%, -50%);

        svg {
          transform: scale(1.1);

          /** Mobile. */
          @media (max-width: 1023px) {
            transform: scale(0.7);
          }
        }
      }
    }

    .flex-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
        padding-top: 0;
      }

      .text-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 50px 0;

        .text {
          color: #124a41;
          text-align: center;
          font-size: 16px;
          max-width: 600px;
          line-height: 1.4;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          strong {
            font-weight: 600;
          }
        }
      }
    }
  }

  .section-about-2 {
    position: relative;
    width: 100%;
    // aspect-ratio: 960/977;
    background-color: #fffdf1;
    padding: 20px 0 120px 0;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 40px 0;
      aspect-ratio: unset;
      max-height: unset;
      margin-top: 0;
      background-color: #f8efe9;
      min-height: unset;
    }

    &::before {
      content: "";
      position: absolute;
      top: -50px;
      right: 0;
      width: 108px;
      height: 125.5px;
      background-image: url(../../src/assets/images/folha-2.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 6;

      @media (max-width: 768px) {
        width: calc(108px / 2);
        height: calc(125.5 / 2);
        top: 0;
        transform: unset;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
      background-image: url(../../src/assets/images/background-slider.jpg);
      background-repeat: repeat;
      background-size: cover;
      z-index: 0;

      @media (max-width: 768px) {
        height: 200px;
      }
    }

    .container {
      height: 100%;

      @media (max-width: 768px) {
        height: unset;
        padding: 0;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .title-wrapper {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      max-width: 600px;
      margin-bottom: 50px;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      .title {
        text-align: center;
        margin-bottom: 30px;

        @media (max-width: 768px) {
          font-size: 22px;
        }
      }
    }

    .slider-wrapper {
      padding-top: 50px;

      @media (max-width: 768px) {
        padding-top: 20px;
      }
    }

    .gallery {
      position: relative;

      @media (max-width: 768px) {
        margin-top: 0;
        overflow: hidden;
      }

      .owl-item {
        &.center {
          .item {
            transform: scale(1);
          }
        }
      }

      .item {
        position: relative;
        margin: 0 auto;
        aspect-ratio: 16/9;
        transform: scale(0.85);
        transition: transform 350ms ease;

        @media (max-width: 768px) {
          height: 250px;
          width: 100%;
          margin: 0 auto;
          padding: 0;
        }

        .content {
          width: 100%;
          max-width: 610px;
          margin: 0 auto;
        }

        &__row-zoom {
          display: flex;
          justify-content: flex-end;
          position: relative;
          top: 0;
          right: 120px;
          z-index: 2;

          @media (max-width: 768px) {
            right: 0;
            top: 0;
          }

          a {
            font-size: 14px;
            color: black;
            line-height: 1.2;
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              transform: scale(0.7);
            }

            span {
              margin-right: 10px;
              transition: transform 350ms ease;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }

        &__row-image {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          height: 100%;

          @media (max-width: 768px) {
            height: 100%;
            box-shadow: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .caption {
            width: 100%;
            height: 6%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            padding: 10px 12px;
            right: 0;
            bottom: 5px;

            @media (max-width: 768px) {
              display: none;
            }

            span {
              font-size: 12px;
              padding-top: 2px;
              color: white;
              font-weight: 400;
              letter-spacing: 1px;
              right: 0;
              text-align: center;
              position: relative;
              text-shadow: 0px 0px 13px #000000;

              @media (max-width: 768px) {
                text-shadow: 0px 0px 8px #000000;
              }
            }
          }
        }
      }

      .owl-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        @media (max-width: 768px) {
          margin-top: 40px;
        }

        .owl-dot {
          width: 10px;
          height: 10px;
          background-color: transparent;
          border: 1px solid #0f2e4b;
          border-radius: 50%;
          margin: 0 3px;
          transition: background-color 350ms ease;

          &.active {
            background-color: #0f2e4b;
          }
        }
      }

      &__navigation {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(50%);
        z-index: 2;

        @media (max-width: 768px) {
          justify-content: center;
          bottom: 20px;
          height: 0px;
          top: unset;
          transform: unset;
        }

        .arrow {
          opacity: 1;
          transition: 250ms;

          @media (max-width: 768px) {
            transform: scale(0.4);
          }

          &:hover {
            opacity: 0.6;
          }

          &__prev {
            position: absolute;
            left: 21.5vw;
            transform: scale(0.6);
            transform-origin: 0 50%;

            @media (max-width: 768px) {
              transform: translateX(-50%) scale(0.5);
            }
          }

          &__next {
            position: absolute;
            right: 21.5vw;
            transform: scale(0.6);
            transform-origin: 100% 50%;

            img {
              filter: contrast(0.5);
            }

            @media (max-width: 768px) {
              transform: translateX(50%) scale(0.5);
            }
          }
        }
      }
    }

    .flex-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
        padding-top: 0;
      }

      .left {
        width: 70%;

        @media (max-width: 768px) {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .right {
        width: 40%;

        @media (max-width: 768px) {
          width: 100%;
          padding: 0 15px 40px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 150px;

          @media (max-width: 768px) {
            justify-content: center;
            align-items: center;
            margin: 40px 0;
          }

          .subtitle {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 1.4;
            font-weight: 400;
            color: #fff;

            @media (max-width: 768px) {
              font-size: 14px;
              text-align: center;
            }
          }

          .title {
            position: relative;
            margin-bottom: 20px;
            font-size: 42px;
            color: #fff;

            @media (max-width: 768px) {
              margin-bottom: 30px;
              font-size: 28px;
            }
          }

          .text {
            color: #fff;
            line-height: 1.4;
            font-weight: 200;
            font-size: 15px;

            @media (max-width: 768px) {
              text-align: center;
              font-size: 14px;
              padding: 0;
            }

            strong {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .section-image {
    position: relative;
    width: 100%;
    aspect-ratio: 640/397;

    @media (max-width: 768px) {
      aspect-ratio: unset;
      height: auto;
    }

    .container {
      height: 100%;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 350px;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(1, 44, 45, 1), transparent);
        z-index: 2;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .flex-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 2;

      .top {
        padding-top: 40px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -60px;
          height: 50px;
          width: 2px;
          background-color: #dd4a0a;
          left: 50%;
          transform: translateX(-50%);
        }

        .title {
          color: white;
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: -70px;

        @media (max-width: 768px) {
          bottom: -530px;
        }

        .cards {
          width: 100%;
          height: 150px;
          border-radius: 20px;
          background-color: #e9e6d3;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          @media (max-width: 768px) {
            flex-direction: column;
            height: auto;
            padding: 15px 0;
          }

          .card {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;

            @media (max-width: 768px) {
              margin: 20px 0;
            }

            img {
              max-width: 40px;
              margin-bottom: 12px;
            }

            &-text {
              color: #124a41;
              font-weight: 300;
              font-size: 14px;
              margin-bottom: 8px;
            }

            .card-text-2 {
              color: #124a41;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .section-ficha {
    position: relative;
    overflow: hidden;
    background-color: #fffdf1;
    height: auto;
    padding: 120px 0 80px 0;

    @media (max-width: 768px) {
      padding: 590px 0 140px 0;
      aspect-ratio: unset;
      height: auto;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -60px;
      left: 0;
      width: 244px;
      height: 244.5px;
      background-image: url(../../src/assets/images/planta-3.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 6;

      @media (max-width: 768px) {
        width: calc(244px / 2);
        height: calc(244.5px / 2);
        bottom: 0;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 2;

      @media (max-width: 768px) {
        padding: 0 8px;
      }
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0 50px 0;

      h2 {
        text-align: center;
        font-size: 42px;
        color: #e44a04;

        @media (max-width: 768px) {
          font-size: 32px;
        }
      }
    }

    .ficha {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 80px;
      margin: 0 auto;
      flex-direction: column;

      @media (max-width: 768px) {
        margin-top: 20px;
      }

      img {
        width: 90%;
        height: 90%;
        object-fit: cover;
      }
    }

    .obs {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 50px;

      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
      }

      span {
        color: #152c4e;
        line-height: 1.2;
        font-size: 12px;
        font-weight: 600;

        @media (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }

  .section-evolucao {
    position: relative;
    width: 100%;
    padding: 90px 0;

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      .title {
        color: white;
        position: relative;

        &::before {
          content: "~";
          position: absolute;
          bottom: 1px;
          left: 94px;
          color: white;
          transform: rotate(90deg);
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: 300;
        }
      }
    }

    .evolucao {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 50px;
      max-width: 900px;
      position: relative;

      @media (max-width: 768px) {
        gap: 35px 50px;
        grid-template-columns: repeat(1, 1fr);
      }

      .evolucao-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .evolucao-title {
          color: white;
          font-size: 16px;
          margin-bottom: 12px;
        }

        .progress-bar {
          width: 100%;
          height: 30px;
          background-color: #4d1602;
          border: 1px solid #4d1602;
          border-radius: 15px;
          position: relative;
          overflow: hidden;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
        }

        .progress {
          height: 100%;
          background-color: #e44a04;
          display: flex;
          align-items: center;
          border-radius: 15px;
          justify-content: center;
          position: relative;
          color: #e44a04;
          font-weight: bold;
          transition: width 0.3s ease-in-out;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
        }

        .percentage {
          position: absolute;
          width: 100%;
          text-align: center;
          font-size: 11px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }

  .section-about-3 {
    position: relative;
    width: 100%;
    padding: 30px 0 50px 0;
    height: 100vh;
    max-height: 1080px;
    background-color: #fff;

    @media (max-width: 768px) {
      padding: 30px 0;
      height: auto;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      height: 100%;
      max-width: 1600px;

      @media (max-width: 768px){
        padding: 0;
      }
    }

    .flex-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .left {
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 40px;
        order: 2;
      }
    }

    .tour {
      position: relative;
      background-color: black;
      width: 100%;
      height: 100%;
      margin: 30px auto;
      box-shadow: 0px 18px 35px 0px rgba(0, 0, 0, 0.45);

      @media (max-width: 1023px) {
        width: 100vw;
        height: 400px;
        margin: 10px auto 30px auto;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      .title {
        color: #e44c0b;
        margin-bottom: 10px;
      }

      .text {
        font-size: 14px;
        line-height: 1.8;
        color: #124a41;
        text-align: center;
        max-width: 300px;
      }
    }
  }

  .section-form {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 40px 0 10px 0;
    aspect-ratio: 16/10;
    background-color: #fffdf1;

    @media (max-width: 768px) {
      min-height: unset;
      min-width: unset;
      aspect-ratio: unset;
      height: unset;
      margin-top: 0;
      height: 800px;
      padding: 0;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;

      @media (max-width: 768px) {
        right: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      height: 100%;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;

      @media (max-width: 1023px) {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        max-width: 370px;

        @media (max-width: 1360px) {
          transform: scale(0.9);
        }

        @media (max-width: 768px) {
          max-width: 100%;
          width: 100%;
          position: relative;
        }
      }

      .right {
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .title-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        position: relative;

        @media (max-width: 768px) {
          margin-bottom: 30px;
          padding: 0 30px;
        }

        .title {
          position: relative;
          font-size: 32px;
          color: #fff;
          text-align: center;

          @media (max-width: 768px) {
            color: #fe6421;
          }
        }
      }

      .text {
        display: block;
        text-align: center;
        line-height: 1.4;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        font-size: 20px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          font-size: 18px;
          color: #124a41;
        }
      }

      .cta-form {
        width: 340px;
        position: relative;
        box-shadow: 0px 21px 30px 0px rgba(0, 23, 42, 0.33);

        @media (max-width: 768px) {
          width: 100%;
          transform: scale(0.95);
        }

        .form {
          background-color: rgba(255, 255, 255, 0.85);
          width: 100%;
          margin: 0;
          padding: 15px 25px 15px 25px;

          .form-title {
            text-align: center;
            text-transform: uppercase;
            color: #0a302a;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 1.4;
            padding-bottom: 8px;
          }

          .field-group {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid rgba(254, 100, 33, 0.5);
            transition: border-color time(fast) ease(fade);
            margin: 20px 0 40px 0;

            label {
              font-size: 14px;
              color: #fe6421;
              font-weight: 600;
              position: relative;
              bottom: 14px;
            }

            .placeholder {
              color: rgba(5, 43, 37, 0.5);
              font-size: 13px;
              font-weight: 500;
              position: absolute;
              bottom: 8px;
              left: 0;
              pointer-events: none;
              transition: opacity time(default) ease(fade);
            }

            .field {
              background-color: transparent;
              color: #0a302a;
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              padding: 0 0 8px;
              border: none;
              width: 100%;
              display: block;
            }

            &.invalid {
              border-color: red;
            }
            &.focus,
            &.not-empty {
              .placeholder {
                opacity: 0;
              }
            }
          }

          .form-submit {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 20px;
            margin-top: -20px;
            padding: 0;

            button {
              position: relative;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 50px;
              text-align: center;
              cursor: pointer;
              background-color: #fe6421;
              border: 2px solid #fe6421;
              box-shadow: 0px 15px 23.52px 0.48px rgba(0, 0, 0, 0.56);
              font-size: 18px;
              color: #fff;
              transition:
                letter-spacing 350ms ease,
                background-color 350ms ease,
                border 350ms ease,
                color 350ms ease;

              &:hover {
                letter-spacing: 2px;
                background-color: white;
                color: #fe6421;
              }
            }
          }

          .terms-option {
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .checkbox {
              background-color: transparent;
              padding-left: 25px;
              border: none;
              position: relative;
              margin: 0;
              bottom: -2px;

              &-text {
                color: rgba(0, 0, 0, 0.5);
                font-size: 8px;
                text-align: left;
                margin-left: 5px;
                line-height: 1.4;
                font-weight: normal;
                display: block;

                a {
                  font-weight: bold;
                  color: #fff;
                }
              }
            }

            &.active button::before {
              background-color: #fff;
              border-color: #fff;
            }
          }
        }
      }
    }

    .download-button {
      position: absolute;
      bottom: 7%;
      right: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: "Jitter", sans-serif;
      z-index: 2;
      font-size: 28px;
      letter-spacing: 1.3px;
      transition: opacity 350ms ease;

      @media (max-width: 768px) {
        font-size: 22px;
        width: 100%;
        bottom: 90px;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        content: "~";
        position: absolute;
        bottom: 0;
        right: 28px;
        color: white;
        transform: rotate(90deg);
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 300;

        @media (max-width: 768px) {
          right: 115px;
          bottom: -2px;
        }
      }

      &:hover {
        opacity: 0.6;
      }

      a {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      img {
        margin-right: 12px;
        max-width: 18px;
        position: relative;
        bottom: 4px;
      }
    }
  }
}
